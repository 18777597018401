/* RESET
--------------------------------------------------------------------------------------------------------------- */
html,body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td { margin:0; padding:0; border: none; }
* { background-repeat: no-repeat; padding:0; margin:0;}
form, fieldset { border:none; }
table {border-collapse:collapse; border-spacing:0; }
ol, ul, li {list-style:none; }
img { border:0; -ms-interpolation-mode:bicubic; vertical-align:middle; }
a:link, a:visited, a:hover, a:active, a:focus { outline:none; }
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display:block; }
html { font-size:100%; }
input[type=radio] { margin-right: 5px;margin-left: 5px; }


/* CSS3 default animation
--------------------------------------------------------------------------------------------------------------- */
.animate-all { -webkit-transition: all 0.2s ease; -moz-transition: all  0.2s ease; -o-transition: all  0.2s ease; -ms-transition: all 0.2s ease; transition: all 0.2s ease; }

/* Structure
--------------------------------------------------------------------------------------------------------------- */
body { background: #eee; padding:80px 0 80px 0; } 
#page { padding:0 20px; } 
#page-content { padding-top: 20px; }

/* FONT
--------------------------------------------------------------------------------------------------------------- */
body { line-height:1.8em; font-family: "Open Sans", Arial, Tahoma, Verdana, sans-serif; color:#222; width:100%; }
 

/* Header
--------------------------------------------------------------------------------------------------------------- */
.navbar-inverse .navbar-brand { color: #FFF; font-size: 1.6em; text-transform: uppercase; background: #e83f46 url('../images/picto-logo.png') left center no-repeat; padding-left: 50px; line-height: 1.2em; }
.navbar-inverse .navbar-brand:hover { background-color: #eb4f46; }
.navbar-nav a { font-size:1.3em; }

.navbar-fixed-top { border:0; }

.navbar-fixed-top .navbar-collapse, 
.navbar-fixed-bottom .navbar-collapse { max-height:inherit; } 

.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:hover, .navbar-inverse .navbar-nav>.active>a:focus {
    color: #333;
    background-color: #eee; 
    margin-top: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
}


/* APP Generic CSS
--------------------------------------------------------------------------------------------------------------- */
.table { margin: 10px 0 20px; }
.table tr th { font-weight: normal; }
.table>thead>tr>th { font-weight: bold; } 
.table>tbody>tr>td,
.table>tbody>tr>th { vertical-align: middle; border:none; }
.table>tbody>tr { border-bottom:1px solid #ddd; }
.table>tbody>tr:hover { background-color: #F9F9F9; }

label { font-weight: normal; font-size: 1.13em; }

.alert { padding:7px 15px; }

.uneditable-input { background-color: transparent; }

.btn { -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px;  }
.btn-new { float: right; margin:0 0 30px 0; }
.btn-primary { background: #20a8d8; border: none; }
.btn-primary:hover {  }

.login-page { padding:8% 0 0; } 
.form-signin { max-width: 330px; padding: 15px; margin: 0 auto; background-color: rgba(0,0,0,0.4); color:#fff; }
.form-signin h3 { color:#ddd; margin:10px 0 20px 0; text-align: center; }
.form-signin a { color: #fff; }
.form-signin .logo { margin-top: 10px; }
 
.description { color:#888; font-style:italic; line-height: 1.1em; }
 
 
/* EUROVERT
--------------------------------------------------------------------------------------------------------------- */
a { color:#232323; text-decoration: none; }
a:hover { color:#c94148; }

h1 { font-size:2em; color:#222; font-weight: normal; margin: 0 0 10px 0; }
h2 { font-size:1.7em; color:#444; font-weight: lighter; margin: 10px 0; }
h3 { font-size:1.4em; color:#444; font-weight: lighter; margin:10px 0; } 
h4 { font-size:1.1em; color:#444; font-weight: normal; margin:10px 0; }

h1.page-title { float:left; margin-left:10px; } 
h2.page-subtitle { color:#777; font-weight: lighter; float:left; clear: left; margin: 0 0 10px 10px; }
h2.page-subtitle a { color:#777; }


.section-title { border-top:1px solid #ddd; padding:30px 0 0; }
.section-title h2 { float: left; margin:0; }
.section-title h2 i { color:#bbb; }
.section-title a { float:right; }

/* Panel */
.panel { box-shadow:0; border-radius: 0; border: 1px solid #e1e6ef; margin-bottom: 24px; }
.panel-heading { background: #fafafa; color:#999; }
.panel-projects,
#map-projects,
.panel-companies,
.panel-users { margin-top:20px; }


/* Card */
.card { position: relative; padding:35px 30px; border-radius: 0; border: 1px solid #e1e6ef; margin-bottom: 24px; background-color: #fff; }
.card h2 { font-size: 1.3em; }
.card h3 { font-size:1.2em; margin:10px 0 0; } 
.card h3 a { color:#20a8d8; } 
.card .jobtitle { color:#999; margin:0 0 10px 0; } 

.card .btn-group { opacity:1; display: none; position: absolute; bottom:15px; right:15px;} 
.card:hover .btn-group { display:block; }

.card .access-right { opacity:1; display: none; position: absolute; top:15px; right:15px;} 
.card:hover .access-right { display:block; }
 
 
/* vCard */
.vcard { float:left; position: relative;  padding:35px 30px; margin:10px 0; background-color: #fff; border: 1px solid #e1e6ef; text-align: center; } 
.vcard .n { color:#e84047; font-size: 1.2em; font-weight: normal; }
.vcard .role { color:#888; }
.vcard .info a { color:#232323; }


/* Login / Logout page */
body.login-page { background: url('../images/back01.jpg') no-repeat center fixed; -webkit-background-size: cover; background-size: cover; }
 
/* List */
tr > td > .btn-group { opacity: 0.6; } 
tr:hover > td > .btn-group { opacity: 1;}

/* Map */
.gm-style-iw { width:300px; min-height: 150px; }


/* Drop Media */
#dropmedia { cursor: pointer; border: 2px dashed #ddd; border-radius: 5px; background: white; min-height:200px; margin: 20px 0; padding:30px; }
#dropmedia:hover { border: 2px dashed #bbb; }
.dz-message { text-align: center; font-size: 1.3em; color:#888; line-height: 1.3em; }
.dz-message h3 { font-size: 1.5em; }
.dz-message .note { font-size: 0.7em; }
.dropzone .dz-preview .dz-image,
.dropzone .dz-preview .dz-progress { border-radius: 0px; }


/* Photos */ 
.photos { margin-top:40px; margin-bottom: 40px; }
.photo { max-width:340px; }
.photo img:hover { opacity:0.8; }
.photo-comment { padding:10px 0; }
.photo-date { color:#888; }


/* project state */
.state { padding: 1px 5px; color: #666; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; margin-left: 20px; }
.state-1 { background-color: #67b369; border: 1px solid #67b369; color: #fff;}
.state-2 { background-color: #999; border: 1px solid #999; color: #fff; }


 

	
/* RESPONSIVE
--------------------------------------------------------------------------------------------------------------- */
@media (min-width: 769px) {
	.navbar-left { margin-left: 40px; }
	
	
}

@media (max-width: 768px) {
	
	.navbar-inverse .navbar-nav > li > a { font-size: 1.2em; padding:15px 10px 15px 20px; }
	
	.panel-projects .panel-body { padding:0; }
	.panel-body tr { padding:10px 20px; float: left; width:100%; }
	.panel-body td { padding:0!important; width:100%; display:block; }
	.xs-arrow-nav { float: right; font-size: 24px; } 
	
	.list-type { margin:0 0 8px 0; }
	.project-name { font-size:1.2em; margin:5px 0 5px 0;}
	.project-company a { color:#aaa; }
	
	.company-name { font-size:1.2em; margin:5px 0 5px 0; }
	.company-postcode,
	.company-city { color:#aaa; float: left; width:auto!important; margin:0 10px 0 0; }
	
	.card h2 { font-size: 1.3em; }
	.card h3 { font-size:1.1em; }
	
	.photo img { max-width: 90%; }
	
	.project-show {}
	.project-show .state { float:left; clear: both; margin:0 0 10px 10px;}
	.project-show .btn-new.btn-primary { float:right; clear: both; }
	.project-show .btn-new { margin:0 0 20px 0; }
}